.notifNova {
  &:after {
    font-family: FontAwesome;
    color: var(--corAnalogaClara);
    content: '\f111';
    margin-left: 10px;
    width: 15px;
  }
  & > p {
    flex: 1;
  }
}
.notifDate {
  display: block;
  color: #777;
  font-size: 0.75rem;
}
